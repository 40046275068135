<!--
 * @Descripttion: 活动数据
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-08-19 09:07:37
 * @LastEditors: alex.yang
 * @LastEditTime: 2022-08-19 14:13:14
-->
<template>
    <div class="activeData">
        <el-tabs v-model="activeName" type="card" >
            <el-tab-pane label="进行中" name="first">
                <div class="content">
                    <el-table :data="tableData" style="width: 100%" 
                        :header-cell-class-name="headerStyle" v-loading="loading">
                        <el-table-column type="expand">
                            <template slot-scope="scope">
                                <el-table :data="scope.row.activity" border :stripe="true" style="width: 100%"
                                    :header-cell-class-name="listStyle">
                                    <el-table-column align="center" prop="title" label="活动名称"></el-table-column>
                                    <el-table-column align="center" prop="activity_type" label="活动类型"></el-table-column>
                                    <el-table-column align="center" label="活动状态">
                                        <template slot-scope="scope">
                                            <div v-if="scope.row.status == 0">暂停</div>
                                            <div v-if="scope.row.status == 1">开启</div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column align="center" prop="guide" label="活动说明" :show-overflow-tooltip="true"></el-table-column>
                                    <el-table-column align="center" prop="create_timex" label="添加时间" width="200"></el-table-column>
                                </el-table>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" label="方案名称" prop="pname"></el-table-column>
                        <el-table-column align="center" label="方案类型" prop="plan_type_transform"></el-table-column>
                        <el-table-column align="center" label="方案状态" prop="desc">
                            <template slot-scope="scope">
                                <div v-if="scope.row.state == 0">暂停</div>
                                <div v-if="scope.row.state == 1">进行中</div>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" label="活动总数" prop="activity_count"></el-table-column>
                        <el-table-column align="center" label="活动进行中数" prop="start_activity_count"></el-table-column>
                        <el-table-column align="center" label="最后修改时间" prop="update_time" :show-overflow-tooltip="true"></el-table-column>
                        <el-table-column align="center" label="创建时间" prop="create_time" :show-overflow-tooltip="true"></el-table-column>
                    </el-table>
                </div>
            </el-tab-pane>

            <el-tab-pane label="未开始" name="second">
                <div class="content">
                    <el-table :data="tableData" style="width: 100%" 
                        :header-cell-class-name="headerStyle" v-loading="loading">
                        <el-table-column type="expand">
                            <template slot-scope="scope">
                               <el-table :data="scope.row.activity" border style="width: 100%" :header-cell-class-name="listStyle">
                                    <el-table-column align="center" prop="title" label="活动名称"></el-table-column>
                                    <el-table-column align="center" prop="activity_type" label="活动类型"></el-table-column>
                                    <el-table-column align="center" prop="id" label="活动状态">
                                        <template slot-scope="scope">
                                            <div v-if="scope.row.status == 0">暂停</div>
                                            <div v-if="scope.row.status == 1">开启</div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column align="center" prop="guide" label="活动说明" :show-overflow-tooltip="true"></el-table-column>
                                    <el-table-column align="center" prop="create_timex" label="添加时间" width="200"></el-table-column>
                                </el-table>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" label="方案名称" prop="pname"></el-table-column>
                        <el-table-column align="center" label="方案类型" prop="plan_type_transform"></el-table-column>
                        <el-table-column align="center" label="方案状态" prop="desc">
                            <template slot-scope="scope">
                                <div v-if="scope.row.state == 0">暂停</div>
                                <div v-if="scope.row.state == 1">进行中</div>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" label="活动总数" prop="activity_count"></el-table-column>
                        <el-table-column align="center" label="活动进行中数" prop="start_activity_count"></el-table-column>
                        <el-table-column align="center" label="最后修改时间" prop="update_time" :show-overflow-tooltip="true"></el-table-column>
                        <el-table-column align="center" label="创建时间" prop="create_time" :show-overflow-tooltip="true"></el-table-column>
                    </el-table>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import common from "@/utils/common.js"
export default {
    data () {
        return {
            activeName: 'first',
            loading: false,
            state: 1,
            shop_id: '',
            tableData: []
        }
    },
    mounted () {
        let info = JSON.parse(localStorage.getItem('shop'));
        if(info.name){
            this.shop_id = info.id;
        }else{
            this.shop_id = info.shop_id;
        }
        this.getPlanIndex();
    },
    watch:{
        activeName(newVal,oldVal){
            if(newVal == 'first'){
                this.state = 1
            }else{
                this.state = 0
            }
            this.getPlanIndex();
        },
    },
    methods: {
        listStyle(){
            return 'listStyle'
        },
        headerStyle () {        
            return 'tableStyle'      
        },
        getPlanIndex(){
            let params = {
                shop_id: this.shop_id,
                state: this.state
            }
            this.loading = true;
            common.connect("/customerservicev1/shop_activity/planIndex",params,(res)=>{
                this.tableData = res.data;
                this.loading = false;
            });
        }
    },
}
</script>

<style lang='scss'>
.activeData{
    .content{
        padding: 20px;
        .title-wrap{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin: 15px 0;
            .tag{
                width: 4px;
                height: 15px;
                background: #409EFF;
                border-radius: 10px;
                margin-right: 10px;
            }
            .title{
                font-size: 14px;
                font-weight: bold;
                padding-left: 10px;
            }
        }
    }
    .el-tabs__header{
        border-bottom: none;
        .el-tabs__nav{
            border: none;
            border-radius: 4px;
            overflow: hidden;
        }
        .el-tabs__item.is-active{
            color: #fff;
            background: #409EFF;
            border: 1px solid #409EFF !important;
        }
        .el-tabs__item{
            color: #333;
            border: 1px solid #E4E7ED !important;
        }
    }
    .tableStyle{
        background: #71b0ef!important;
        color: #fff;
    }
    .listStyle{
        background: #eee!important;
        color: #000;
    }
}
</style>
