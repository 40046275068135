<!--
 * @Descripttion: 基础信息
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-06-30 09:00:00
 * @LastEditors: dxl 1668645906@qq.com
 * @LastEditTime: 2023-08-25 11:41:00
-->
<template>
    <div class="basicInformation">
        <div class="box-wrap" v-loading="loading">
            <el-descriptions :column="1" size="medium" v-if="info.name" border
                labelClassName="labelClassName" contentClassName="contentClassName">
                <el-descriptions-item label="企业名称">{{info.name}}</el-descriptions-item>
                <el-descriptions-item label="品牌名称">{{info.enterprise_brand.name}}</el-descriptions-item>
                <el-descriptions-item label="门店logo">
                    <div class="image-wrap">
                        <img @click="clickImage(info.logo)" :src="info.logo" class="pic" />
                    </div>
                </el-descriptions-item>
                <el-descriptions-item label="门店名称">{{info.name}}</el-descriptions-item>
                <el-descriptions-item label="店长">{{info.shop_owner !=null ?info.shop_owner.username:''}}</el-descriptions-item>
                <el-descriptions-item label="联系电话">{{info.shop_owner != null?info.shop_owner.mobile:''}}</el-descriptions-item>
                <el-descriptions-item label="合约到期日期">{{info.contract_end_time}}</el-descriptions-item>
                <el-descriptions-item v-if="info.systemAdmin" label="客服负责人">{{info.systemAdmin.nickname}}</el-descriptions-item>
                <el-descriptions-item label="地址">{{info.address}}</el-descriptions-item>
                <el-descriptions-item label="门店添加产品">
                    <el-tooltip placement="top" :content="info.is_open_add_shopgoods==1?'开启':'关闭'">
                        <el-switch v-model="info.is_open_add_shopgoods" 
                            active-color="#13ce66" 
                            inactive-color="#ff4949"
                            @change="changeOpenAddShopGood(info.id)"
                            >
                        </el-switch>
                    </el-tooltip>
                </el-descriptions-item>
                <el-descriptions-item label="门店获客开关">
                    <el-tooltip placement="top" :content="info.is_open_customer_acquisition==1?'开启':'关闭'">
                        <el-switch v-model="info.is_open_customer_acquisition"
                            active-color="#13ce66"
                            inactive-color="#ff4949"
                            @change="changeOpenCustomerAcquisition(info.id)"
                            >
                        </el-switch>
                    </el-tooltip>
                </el-descriptions-item>
            </el-descriptions>
            <div v-if="isShowImages">
                <el-image-viewer 
                :on-close="closeViewer" 
                :url-list="imageList" />
            </div>
        </div>
    </div>
</template>

<script>
import common from "@/utils/common.js"
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
    components: {
      ElImageViewer,
    },
    data () {
        return {
            shopId: 0,
            info: {},
            isShowImages: false,
            imageList: [],
            loading: false
        }
    },
    mounted () {
        let info = JSON.parse(localStorage.getItem('shop'))
        if(info.name){
            this.shopId = info.id;
        }else{
            this.shopId = info.shop_id;
        }
        this.getBaseShow();
    },
    methods: {
        clickImage(item){
            this.isShowImages = true;
            this.imageList.push(item);
        },
        // 关闭查看器
        closeViewer() {
            this.isShowImages = false;
            this.imageList = [];
        },
        getBaseShow(){
            let params = {
                shop_id: this.shopId
            }
            this.loading = true;
            common.connect("/customerservicev1/shop/baseShow",params,(res)=>{
                this.info = res.data;
                this.loading = false;
            });
        },
        //开启关闭门店添加产品功能
        changeOpenAddShopGood(info){
            let params = {
                shop_id: this.shopId
            }
            this.loading = true;
            common.connect("/customerservicev1/shop/setAddShopGood",params,(res)=>{
                this.loading = false;
            });
        },
        //开启获客
        changeOpenCustomerAcquisition(info){
            let params = {
                shop_id: this.shopId
            }
            this.loading = true;
            common.connect("/customerservicev1/shop/setCustomerAcquisition",params,(res)=>{
                this.loading = false;
            });
        }

    },
}
</script>

<style lang='scss'>
.basicInformation{
    padding: 20px;
    .box-wrap{
        min-height: 400px;
    }
    .labelClassName{
        width: 120px;
        font-size: 15px;
        color: #000;
        line-height: 40px;
    }
    .contentClassName{
        font-size: 15px;
        color: #666;
        margin-left: 20px;
        line-height: 40px;
        width: calc(100% - 105px);
    }
    .image-wrap{
        width: 100%;
        .pic{
            width: 100px;
            cursor: pointer;
        }
    }
    .content{
        line-height: 34px;
        margin-top: -15px;
    }
}
</style>

