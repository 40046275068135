<!--
 * @Descripttion: 支付审核
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-06-30 09:18:49
 * @LastEditors: alex.yang
 * @LastEditTime: 2022-08-23 10:10:26
-->
<template>
    <div class="paymentAudit" v-loading="loading">
        <div class="null-wrap" v-if="applyInfo == null">客户暂未提交审核资料</div>
        <div v-else>
            <el-descriptions :column="1" size="medium" border
                labelClassName="labelClassName" contentClassName="contentClassName">
                <el-descriptions-item label="营业执照">
                    <div v-if="applyInfo != null">
                        <div class="image-wrap">
                            <img @click="clickImage(applyInfo.business_license_img)" class="pic" :src="applyInfo.business_license_img" />
                        </div>
                    </div>
                    <div v-else>/</div>
                </el-descriptions-item>
                <el-descriptions-item label="营业审核状态">
                    <div v-if="applyInfo != null">
                        <div style="color: #f5bd00;" v-if="applyInfo.business_license_img_state == 1">待审核</div>
                        <div style="color: #00d90d" v-if="applyInfo.business_license_img_state == 2">审核通过</div>
                        <div style="color: #ff0100;" v-if="applyInfo.business_license_img_state == 3">审核不通过</div>
                    </div>
                    <div style="color: #999999;" v-else>未提交</div>
                </el-descriptions-item>
                <el-descriptions-item label="营业审核时间">
                    <div v-if="applyInfo != null">
                        <div>{{applyInfo.business_license_submit_date}}</div>
                    </div>
                    <div v-else>/</div>
                </el-descriptions-item>
            </el-descriptions>
            <div class="btn-wrap" style="margin-bottom: 20px" >
                <el-button type="primary" @click="clickAudit(1)">营业执照审核</el-button>
                <el-button type="danger" @click="resetAudit(1)">重置营业执照资料</el-button>
            </div>

            <el-descriptions :column="1" size="medium" border
                labelClassName="labelClassName" contentClassName="contentClassName">
                <el-descriptions-item label="主体类型">
                    <div v-if="applyInfo != null">
                        <div v-if="applyInfo.subject_type == 1">个体工商户</div>
                        <div v-if="applyInfo.subject_type == 2">企业</div>
                    </div>
                    <div v-else>/</div>
                </el-descriptions-item>
                <el-descriptions-item label="账户类型">
                    <div v-if="applyInfo != null">
                        <div v-if="applyInfo.bank_account_type == 1">对公银行账户</div>
                        <div v-if="applyInfo.bank_account_type == 2">个人银行卡账户</div>
                    </div>
                    <div v-else>/</div>
                </el-descriptions-item>
                <el-descriptions-item label="开户行">
                    <div v-if="applyInfo != null">
                        {{applyInfo.bank_account_info}}
                    </div>
                    <div v-else>/</div>
                </el-descriptions-item>
                <el-descriptions-item label="银行卡号">
                    <div v-if="applyInfo != null">
                        {{applyInfo.bank_account_no}}
                    </div>
                    <div v-else>/</div>
                </el-descriptions-item>
                <el-descriptions-item label="联系电话">
                    <div v-if="applyInfo != null">
                        {{applyInfo.apply_mobile}}
                    </div>
                    <div v-else>/</div>
                </el-descriptions-item>
                <el-descriptions-item label="身份证照片">
                    <div v-if="applyInfo != null">
                        <div class="image-wrap">
                            <img @click="clickImage(applyInfo.legal_representative_idimg_head)" class="pic" :src="applyInfo.legal_representative_idimg_head" />
                            <img @click="clickImage(applyInfo.legal_representative_idimg_emblem)" class="pic" :src="applyInfo.legal_representative_idimg_emblem" />
                        </div>
                    </div>
                    <div v-else>/</div>
                </el-descriptions-item>
                <el-descriptions-item label="支付审核状态">
                    <div v-if="applyInfo != null">
                        <div style="color: #f5bd00;" v-if="applyInfo.state == 1">待审核</div>
                        <div style="color: #00d90d" v-if="applyInfo.state == 2">审核通过</div>
                        <div style="color: #ff0100;" v-if="applyInfo.state == 3">审核不通过</div>
                    </div>
                    <div v-else style="color: #999999;">未提交</div>
                </el-descriptions-item>
                <el-descriptions-item label="支付审核参数" v-if="applyInfo.state == 2">
                    <div v-if="applyInfo != null">
                        <div>{{applyInfo.pay_set}}</div>
                    </div>
                    <div v-else>/</div>
                </el-descriptions-item>
                <el-descriptions-item label="支付审核时间">
                    <div v-if="applyInfo != null">
                        <div>{{applyInfo.examine_date}}</div>
                    </div>
                    <div v-else>/</div>
                </el-descriptions-item>
            </el-descriptions>
            <div class="btn-wrap">
                <el-button type="primary" @click="clickAudit(2)">支付材料申请审核</el-button>
                <el-button type="danger" @click="resetAudit(2)">重置支付材料</el-button>
            </div>
        </div>
        <!-- 审核 -->
        <el-dialog
            :visible.sync="isShowAudit" 
            :before-close="handleClose"
            custom-class="dialog-wrap"
            :close-on-click-modal="false">
            <div class="title" v-if="currentIndex == 1">营业执照资料审核确认</div>
            <div class="title" v-if="currentIndex == 2">支付材料申请确认</div>
            <div class="content">
                <div class="item">
                    <div class="tit">审核状态</div>
                    <div class="status-wrap">
                        <el-radio v-model="status" label="2">通过</el-radio>
                        <el-radio v-model="status" label="3">不通过</el-radio>
                    </div>
                </div>
                <div class="item" v-if="currentIndex == 2">
                    <div class="tit">支付类型</div>
                    <div class="status-wrap">
                        <el-radio v-model="pay_type" label="1">普通商户</el-radio>
                        <el-radio v-model="pay_type" label="2">服务商</el-radio>
                    </div>
                </div>
                <div class="item" v-if="pay_type == '1' && currentIndex == 2">
                    <div class="tit">KEY</div>
                    <div class="status-wrap">
                        <el-input v-model="key" placeholder="请填写key"></el-input>
                    </div>
                </div>
                <div class="item" v-if="status == '2' && currentIndex == 2">
                    <div class="tit">商户号码</div>
                    <div class="status-wrap">
                        <el-input oninput="value=value.replace(/[^\d]/g,'')" maxlength="10" v-model="mchId" placeholder="请填写在微信支付商户平台申请完毕的子商户号"></el-input>
                    </div>
                </div>
                <div class="item" v-if="status == '3'">
                    <div class="tit">不通过原因</div>
                    <div class="status-wrap">
                        <el-input type="textarea" v-model="reject" placeholder="请填写不通过原因，如xx图不够清晰等"></el-input>
                    </div>
                </div>
                <div class="btn item">
                    <el-button :loading="btnLoadng" @click="determineAudit" type="primary">确定</el-button>
                </div>
            </div>
        </el-dialog>
        <!-- 重置 -->
        <el-dialog
            :visible.sync="resetShow" 
            :before-close="handleClose"
            custom-class="dialog-wrap"
            :close-on-click-modal="false">
            <div class="title" v-if="resetIndex == 1">是否确定重置营业执照资料审核</div>
            <div class="title" v-if="resetIndex == 2">是否确定重置支付材料申请</div>
            <div class="content">
               <div class="item" style="margin-top: 10px">
                    <div class="tit">涉及敏感操作，请填写密码</div>
                    <div class="status-wrap">
                        <el-input type="password" v-model="password" placeholder="请填写密码"></el-input>
                    </div>
                </div>
                <div class="btn item">
                    <el-button :loading="btnLoadng" @click="clickResetAudit" type="primary">确定</el-button>
                </div>
            </div>
        </el-dialog>
        <div v-if="isShowImages">
            <el-image-viewer 
            :on-close="closeViewer" 
            :url-list="imageList" />
        </div>
    </div>
</template>

<script>
import common from "@/utils/common.js"
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
    components: {
      ElImageViewer,
    },
    data () {
        return {
            shopId: 0,
            applyInfo: {},
            loading: false,
            btnLoadng: false,
            isShowImages: false,
            imageList: [],
            isShowAudit: false,
            status: '2',
            pay_type: '1',
            key: '',
            mchId: '',
            reject: '',
            currentIndex: 1,
            resetShow: false,
            resetIndex: 1,
            password: ''
        }
    },
    mounted () {
        let info = JSON.parse(localStorage.getItem('shop'))
        if(info.name){
            this.shopId = info.id;
        }else{
            this.shopId = info.shop_id;
        }
        this.getApplyShow();
    },
    methods: {
        // 确定审核
        determineAudit(){
            if(this.status == '3' && this.reject == ''){
                this.$message({
                    message: '请填写不通过原因！',
                    type: 'warning'
                });
                return;
            }
            if(this.status == '2' && this.currentIndex == 2 
                && this.mchId == ''){
                this.$message({
                    message: '请填写商户号码！',
                    type: 'warning'
                });
                return;
            }
            if(this.pay_type == '1' && this.currentIndex == 2 
                && this.key == ''){
                this.$message({
                    message: '请填写key！',
                    type: 'warning'
                });
                return;
            }
            let params = {
                shop_id: this.shopId,
                type: this.currentIndex,
                reject: this.reject,
                status: Number(this.status),
            }
            if(this.currentIndex == 2){
                params.mch_id = this.mchId;
                params.pay_type = Number(this.pay_type);
                params.key = this.key;
            }
            this.btnLoadng = true;
            common.connect("/customerservicev1/shop/applyAudit",params,(res)=>{
                if(res.isSuccess == 1){
                    this.getApplyShow();
                    this.isShowAudit = false;
                }
                this.btnLoadng = false;
            });
        },
        // 重置
        clickResetAudit(){
            if(this.password == ''){
                this.$message({
                    message: '请输入密码！',
                    type: 'warning'
                });
                return;
            }
            let params = {
                shop_id: this.shopId,
                type: this.resetIndex,
                password: this.password
            }
            this.btnLoadng = true;
            common.connect("/customerservicev1/shop/applyReset",params,(res)=>{
                if(res.isSuccess == 1){
                    this.getApplyShow();
                    this.resetShow = false;
                }
                this.btnLoadng = false;
            });
        },
        resetAudit(index){
            this.resetShow = true;
            this.resetIndex = index;
        },
        // 门店审核
        clickAudit(index){
            this.isShowAudit = true;
            this.currentIndex = index;
            this.status = '2';
            this.reject = ''
        },
        clickApplyAudit(){},
        handleClose(){
            this.isShowAudit = false;
            this.resetShow = false;
        },
        // 获取数据
        getApplyShow(){
            let params = {
                shop_id: this.shopId,
            }
            common.connect("/customerservicev1/shop/applyShow",params,(res)=>{
                this.applyInfo = res.data.apply_info;
                this.loading = false;
            });
        },
        clickImage(item){
            this.isShowImages = true;
            this.imageList.push(item);
        },
        // 关闭查看器
        closeViewer() {
            this.isShowImages = false;
            this.imageList = [];
        },
    },
}
</script>

<style lang='scss'>
.paymentAudit{
    padding: 20px;
    .labelClassName{
        width: 120px;
        font-size: 15px;
        color: #000;
        line-height: 40px;
    }
    .contentClassName{
        font-size: 15px;
        color: #666;
        margin-left: 20px;
        line-height: 40px;
        width: calc(100% - 105px);
    }
    .null-wrap{
        color: #999;
        text-align: center;
        padding: 20px;
    }
    .image-wrap{
        .pic{
            height: 180px;
            cursor: pointer;
            margin-right: 20px;
        }
    }
    .btn-wrap{
        margin-top: 20px;
        display: flex;
        justify-content: center;
    }
    .dialog-wrap{
        width: 540px;
        .title{
            text-align: center;
            font-size: 18px;
            color: #000;
        }
        .content{
            margin-left: 20px;
            .el-input{
                width: 500px;
            }
            .el-textarea{
                width: 500px;
            }
            .item{
                padding-bottom: 20px;
                .tit{
                    color: #000;
                }
                .status-wrap{
                    padding-top: 20px;
                }
                .el-button{
                    width: 500px;
                }
            }
        }
        .btn-wrap{
            display: flex;
            justify-content: center;
        }
    }
    .el-dialog{
        margin: 0;
        margin-top: 0vh !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        padding-bottom: 10px;
    }
}
</style>
