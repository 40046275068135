<!--
 * @Descripttion: 门店数据分析
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-06-30 09:01:35
 * @LastEditors: alex.yang
 * @LastEditTime: 2023-02-03 13:59:50
-->
<template>
    <div class="dataAnalysis">
        <div class="screen-wraps">
            <commonSelect
                @changeDate="changeDate"
                @clickTagDate="clickTagDate"
            ></commonSelect>
        </div>
        <div class="data-wrap">
            <div class="title-wrap">
                <div class="tag"></div>
                <div class="title">曝光获客</div>
            </div>
            <div class="content-wrap" v-loading="loading2">
                <div class="cw-left">
                    <div class="item">
                        <span>{{exposureData.exposure}}</span>曝光量
                    </div>
                    <div class="item item-wrap">
                        <div class="iw-title">
                            <span>{{exposureData.get_customer}}</span>总获客
                        </div>
                        <div class="item-tag">
                            <div class="it-span">
                                <span>{{exposureData.input_customer}}</span>手动录入
                            </div>
                            <div class="it-span">
                                <span>{{exposureData.online_customer}}</span>线上获客
                            </div>
                        </div>
                    </div>
                </div>
                <div class="cw-right" ref="cwRight">
                    <div id="exposureGrades" :style="{width: width+'px'}" style="height: 350px;"></div>
                </div>
            </div>
        </div>
        <!-- <div class="data-wrap">
            <div class="title-wrap">
                <div class="tag"></div>
                <div class="title">客户分析</div>
            </div>
            <div class="content-wrap pie-wrap" v-loading="loading3" >
                <div id="efficientGrades" :style="{width: (width/3)+'px'}" style="height: 350px;"></div>
                <div id="poolGrades" :style="{width: (width/3)+'px'}" style="height: 350px;"></div>
                <div id="determineGrades" :style="{width: (width/3)+'px'}" style="height: 350px;"></div>
            </div>
        </div> -->
        <div class="data-wrap">
            <div class="title-wrap">
                <div class="tag"></div>
                <div class="title">口碑数据</div>
            </div>
            <div class="content-wrap" v-loading="loading4">
                <div class="cw-left">
                    <div class="cw-left-wrap">
                        <div class="cl-item-al">
                            <div class="cl-data-al">
                                <span>{{wordData.invite_count}}</span>口碑邀约
                            </div>
                            <div class="cl-data-al">
                                <span>{{wordData.write_count}}</span>口碑填写
                            </div>
                        </div>
                        <div class="cl-item-al">
                            <div class="cl-data-al">
                                <span>{{wordData.rate}}</span>填写率
                            </div>
                            <div class="cl-data-al">
                                <span>{{wordData.join_shop_count}}</span>参与门店
                            </div>
                        </div>
                    </div>
                </div>
                <div class="cw-right">
                    <div id="wordGrades" :style="{width: width+'px'}" style="height: 350px;"></div>
                </div>
            </div>
        </div>
        <div class="data-wrap">
            <div class="title-wrap">
                <div class="tag"></div>
                <div class="title">参与活动</div>
            </div>
            <div class="content-wrap" v-loading="loading5">
                <div class="cw-left">
                    <div class="cw-left-wrap">
                        <div class="cl-item-al">
                            <div class="cl-data-al">
                                <span>{{activity.invite_count}}</span>总核销卷
                            </div>
                            <div class="cl-data-al">
                                <span>{{activity.stay_count}}</span>待核销
                            </div>
                        </div>
                        <div class="cl-item-al">
                            <div class="cl-data-al">
                                <span>{{activity.finish_count}}</span>已核销
                            </div>
                            <div class="cl-data-al">
                                <span>{{activity.invalid_count}}</span>失效
                            </div>
                        </div>
                    </div>
                </div>
                <div class="cw-right">
                    <div id="activityGrades" :style="{width: width+'px'}" style="height: 350px;"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import common from "@/utils/common.js"
import commonSelect from "@/components/commonSelect.vue"
import {exposureLineEcharts,activityLineEcharts} from "@/echarts/lineEchartData";
import {efficientPieEcharts,poolPieEcharts,determinePieEcharts} from "@/echarts/pieEchartData";
import {wordBarEcharts} from "@/echarts/barEchartData";
export default {
    components: {
      commonSelect
    },
    data () {
        return {
            dateType: '',
            startDate: '',
            endDate: '',
            shopId: '',
            shopData: {},
            exposureData: {},
            wordData: {},
            activity: {},
            loading1: false,
            loading2: false,
            loading3: false,
            loading4: false,
            loading5: false,
            width: 0
        }
    },
    mounted () {
        let info = JSON.parse(localStorage.getItem('shop'));
        if(info.name){
            this.shopId = info.id;
        }else{
            this.shopId = info.shop_id;
        }
        this.getExposureAndGetCustomerChart();
        this.getCustomerAnalysisChart();
        this.getWordDataChart();
        this.getActivityDataChart();
    },
    methods: {
        setValue(value){
            this.width = value
        },
        // 获取活动数据
        getActivityDataChart(){
            let params = {
                shop_id: this.shopId,
                date_type: this.dateType,
                start_date: this.startDate,
                end_date: this.endDate
            }
            this.loading5 = true;
            common.connect("/customerservicev1/shop/activityAnalysisLineChart",params,(res)=>{
                activityLineEcharts('activityGrades',res.data)
                this.activity = res.data
                this.loading5 = false;
           });
        },
        // 获取口碑数据
        getWordDataChart(){
            let params = {
                shop_id: this.shopId,
                date_type: this.dateType,
                start_date: this.startDate,
                end_date: this.endDate
            }
            this.loading4 = true;
            common.connect("/customerservicev1/shop/invitationAndFillingRectangularDiagram",params,(res)=>{
                wordBarEcharts('wordGrades',res.data);
                this.wordData = res.data;
                this.loading4 = false;
           });
        },
        // 获取客户分析数据
        getCustomerAnalysisChart(){
            let params = {
                shop_id: this.shopId,
                date_type: this.dateType,
                start_date: this.startDate,
                end_date: this.endDate
            }
            this.loading3 = true;
            common.connect("/customerservicev1/shop/customerAnalysisPieChart",params,(res)=>{
                efficientPieEcharts('efficientGrades',res.data.all_customer);
                poolPieEcharts('poolGrades',res.data.pool_customer);
                determinePieEcharts('determineGrades',res.data.determine);
                this.loading3 = false;
           });
        },
        // 获取曝光获客数据
        getExposureAndGetCustomerChart(){
            let params = {
                shop_id: this.shopId,
                date_type: this.dateType,
                start_date: this.startDate,
                end_date: this.endDate
            }
            this.loading2 = true;
            common.connect("/customerservicev1/shop/exposureAndGetCustomerLineChart",params,(res)=>{
                exposureLineEcharts('exposureGrades',res.data);
                this.exposureData = res.data;
                this.loading2 = false;
           });
        },
        // 日期选择
        changeDate(startDate,endDate){
            this.startDate = startDate.slice(0,11)+' 00:00:01';
            this.endDate = endDate.slice(0,11)+' 23:59:59';
            this.getExposureAndGetCustomerChart();
            this.getCustomerAnalysisChart();
            this.getWordDataChart();
            this.getActivityDataChart();
        },
        clickTagDate(item){
            this.dateType = item.label;
            if(item.label != 'date'){
                this.startDate = '';
                this.endDate = '';
                this.getExposureAndGetCustomerChart();
                this.getCustomerAnalysisChart();
                this.getWordDataChart();
                this.getActivityDataChart();
            }
        },
    },
}
</script>

<style lang='scss'>
.dataAnalysis{
    background: #f0f2f5;
    .screen-wraps{
        background: #fff;
        padding: 20px;
    }
    .data-wrap{
        padding: 20px 0;
        border-radius: 6px;
        overflow: hidden;
        background: #fff;
        padding: 0 20px;
        margin: 20px 0;
        .title-wrap{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-top: 20px;
            .tag{
                width: 3px;
                height: 15px;
                background: #409EFF;
                border-radius: 10px;
            }
            .title{
                font-size: 15px;
                font-weight: bold;
                padding-left: 10px;
            }
        }
        .pie-wrap{
            margin-top: -30px;
            padding-bottom: 30px;
        }
        .content-wrap{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            .cw-left{
                display: flex;
                flex-direction: column;
                .item{
                    width: 300px;
                    height: 90px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid #ddd;
                    margin-bottom: 20px;
                    span{
                        display: flex;
                        align-items: baseline;
                        font-size: 24px;
                        color: #000;
                        font-weight: bold;
                        margin-right: 10px;
                    }
                }
                .item-wrap{
                    display: flex;
                    flex-direction: column;
                    .iw-title{
                        display: flex;
                        justify-content: center;
                        align-items: baseline;
                    }
                    .item-tag{
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 12px;
                        margin-top: 10px;
                        .it-span{
                            display: flex;
                            flex-wrap: wrap;
                            align-items: baseline;
                            padding: 0 10px;
                            span{
                                font-size: 20px;
                            }
                        }
                    }
                }
                .cw-left-wrap{
                    border: 1px solid #ddd;
                    height: 140px;
                    width: 300px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    .cl-item-al{
                        display: flex;
                        justify-content: space-between;
                        .cl-data-al{
                            display: flex;
                            flex-wrap: wrap;
                            align-items: baseline;
                            padding: 10px;
                            span{
                                font-size: 20px ;
                                color: #000;
                                font-weight: bold;
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }
            .cw-right{
                width: 100% !important;
            }
        }
    }
}
</style>
