<!--
 * @Descripttion: 门店详情
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-06-30 08:39:54
 * @LastEditors: dxl 1668645906@qq.com
 * @LastEditTime: 2023-08-25 10:52:28
-->
<template>
    <div class="shopDataInfo" ref="shopDataInfo">
        <div class="title-wrap" v-if="shop.name">
            <div class="tag"></div>
            <el-breadcrumb separator-class="el-icon-arrow-right" v-if="jump == 1">
                <el-breadcrumb-item :to="{ name: 'brandData' }">品牌列表</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ name: 'brandDataInfo' }">品牌详情<span>({{info.name}})</span></el-breadcrumb-item>
                <el-breadcrumb-item>门店详情<span>({{shop.name}})</span></el-breadcrumb-item>
            </el-breadcrumb>
            <el-breadcrumb separator-class="el-icon-arrow-right" v-if="jump == 2">
                <el-breadcrumb-item :to="{ name: 'accountManagement'}">账号管理</el-breadcrumb-item>
                <el-breadcrumb-item @click="goBalck" :to="{ name: 'storeDataInfo' }">门店数据<span>({{shopUser.nickname}})</span></el-breadcrumb-item>
                <el-breadcrumb-item>门店详情<span>({{shop.name}})</span></el-breadcrumb-item>
            </el-breadcrumb>
            <el-breadcrumb separator-class="el-icon-arrow-right" v-if="jump == 3">
                <el-breadcrumb-item :to="{ name: 'shopDataList'}">门店列表</el-breadcrumb-item>
                <el-breadcrumb-item>门店详情<span>({{shop.name}})</span></el-breadcrumb-item>
            </el-breadcrumb>
            <el-breadcrumb separator-class="el-icon-arrow-right" v-if="jump == 5">
                <el-breadcrumb-item :to="{ name: 'activityDataInfo'}">活动列表</el-breadcrumb-item>
                <el-breadcrumb-item>门店详情<span>({{shop.name}})</span></el-breadcrumb-item>
            </el-breadcrumb>
            <el-breadcrumb separator-class="el-icon-arrow-right" v-if="jump == 6">
                <el-breadcrumb-item :to="{ name: 'invoiceList'}">发票列表</el-breadcrumb-item>
                <el-breadcrumb-item>门店详情<span>({{shop.name}})</span></el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="title-wrap" v-else>
            <div class="tag"></div>
            <el-breadcrumb separator-class="el-icon-arrow-right" v-if="jump == 4">
                <el-breadcrumb-item :to="{ name: 'accountData'}">账号数据</el-breadcrumb-item>
                <el-breadcrumb-item>门店详情<span>({{shop.shop_name}})</span></el-breadcrumb-item>
            </el-breadcrumb>
            <el-breadcrumb separator-class="el-icon-arrow-right" v-else>
                <el-breadcrumb-item :to="{ name: 'shopDataList' }">门店跟进记录</el-breadcrumb-item>
                <el-breadcrumb-item>门店详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="case-wrap">
            <el-tabs v-model="activeName" type="card">
                <el-tab-pane label="基础信息" name="first">
                    <basicInformation ref="basicInformation"></basicInformation>
                </el-tab-pane>
                <el-tab-pane label="数据分析" name="second">
                    <dataAnalysis ref="dataAnalysis"></dataAnalysis>
                </el-tab-pane>
                <el-tab-pane label="跟进记录" name="thirdly">
                    <followRecords ref="followRecords"></followRecords>
                </el-tab-pane>
                <el-tab-pane label="账号数据" name="fourthly">
                    <accountData ref="accountData"></accountData>
                </el-tab-pane>
                <el-tab-pane label="活动数据" name="fifth">
                    <activeData ref="activeData"></activeData>
                </el-tab-pane>
                <el-tab-pane label="审核" name="sixth">
                    <paymentAudit ref="paymentAudit"></paymentAudit>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
import followRecords from './shopComponents/followRecords'
import basicInformation from './shopComponents/basicInformation'
import dataAnalysis from './shopComponents/dataAnalysis'
import accountData from './shopComponents/accountData'
import activeData from './shopComponents/activeData'
import paymentAudit from './shopComponents/paymentAudit'
export default {
    components:{
        followRecords,
        basicInformation,
        dataAnalysis,
        accountData,
        activeData,
        paymentAudit,
    },
    data () {
        return {
            activeName: 'first',
            info: {},
            shop: {},
            shopUser: {},
            jump: 0,
            type: ''
        }
    },
    mounted () {
        this.type = this.$route.query.type
        if(this.type){
            this.activeName = this.type;
        }
        this.jump = localStorage.getItem('jump');
        this.info = JSON.parse(localStorage.getItem('brand'));
        this.shop = JSON.parse(localStorage.getItem('shop'));
        // console.log(this.shop);
        this.shopUser = JSON.parse(localStorage.getItem('userid'));
        let width =  this.$refs.shopDataInfo.offsetWidth - 400;
        this.$refs.dataAnalysis.setValue(width);
    },
    watch:{
        activeName(newVal,oldVal){
            if (newVal == 'fourthly') {
                this.$refs.accountData.getdoLayout();
            }
        }
    },
    methods: {
        goBalck(){
            //页面的返回必须用back才能生效
            this.$router.back()
        }
    },
}
</script>

<style lang='scss'>
.shopDataInfo{
    background: rgb(240, 242, 245);
    padding: 10px 25px;
    height: calc(100vh - 80px);
    overflow-y: auto;
    .title-wrap{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 5px 0;
        .tag{
            width: 4px;
            height: 15px;
            background: #409EFF;
            border-radius: 10px;
            margin-right: 10px;
        }
        .title{
            font-size: 18px;
            font-weight: bold;
            padding-left: 10px;
        }
    }
    .case-wrap{
        background: #fff;
        margin-top: 20px;
        border-radius: 6px;
        .el-tabs__header{
            padding-top: 20px;
            margin: 0 20px;
        }
    }
    .el-breadcrumb__item{
        .el-breadcrumb__inner{
            color: #000;
            font-weight: bold;
        }
        .is-link{
            color: #333;
            font-weight: 300;
        }
    }
}
</style>
